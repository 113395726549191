import React, { useState }  from "react"
import axios from "axios";
import { Link } from "gatsby"
import Layout from "../components/layout"


const ContactForm = () => {
    

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [desc, setDesc] = React.useState("");
    const [company, setCompany] = React.useState("");
    


    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        console.log('response was ok');  
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;

      setServerState({ submitting: true });

      var data = {
         name : name,
         phone : phone,
         email : email,
         desc : desc
       };

       axios.post('https://kqp9w02cc2.execute-api.us-east-1.amazonaws.com/prod', data)
       .then(r => {
        console.log(r);
        handleServerResponse(true, "Thanks!", form);
    })
    .catch(r => {
      handleServerResponse(false, r.response.data.error, form);
    });


    /*
        axios({
        method: "post",

        url: "https://kqp9w02cc2.execute-api.us-east-1.amazonaws.com/prod",
        
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then(r => {
            console.log(r);
            handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });

        */

    };


    return (
    
         
              <div>
        <form id="contact-me" onSubmit={handleOnSubmit} >

<div class="flex flex-wrap mb-6">

    <div class="relative w-full appearance-none label-floating">
        <input class="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
        id="name" type="text" onChange={e => setName(e.target.value)} placeholder="Your name"required/>
        <label for="name" class="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text">
            Your name
        </label>
    </div>

</div>

{/* 
<div class="flex flex-wrap mb-6">
    <div class="relative w-full appearance-none label-floating">
        <input class="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
        id="company" type="text" onChange={e => setCompany(e.target.value)} placeholder="Your company"/>
        <label for="company" class="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text">
            Your company
        </label>
    </div>
</div>
*/}


<div class="flex flex-wrap mb-6">
    <div class="relative w-full appearance-none label-floating">
        <input class="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
        id="phone" type="tel" onChange={e => setPhone(e.target.value)} placeholder="Your phone"required/>
        <label for="phone" class="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text">
            Your phone
        </label>
    </div>
</div>



<div class="flex flex-wrap mb-6">
    <div class="relative w-full appearance-none label-floating">
        <input class="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
        id="email" type="email" onChange={e => setEmail(e.target.value)} placeholder="Your email"required/>
        <label for="email" class="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text">
            Your email
        </label>
    </div>
</div>


<div class="flex flex-wrap mb-6">
    <div class="relative w-full appearance-none label-floating">
        <textarea class="autoexpand tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
            id="desc" type="text" onChange={e => setDesc(e.target.value)} placeholder="Message..."></textarea>
            <label for="desc" class="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text">Message...
        </label>
    </div>
</div>

<div class="">
        <button class="w-full shadow bg-indigo-700 hover:bg-indigo-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit" disabled={serverState.submitting}>
            Send
        </button>
        {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
                </p>
            )}

    </div>
    </form>
            </div>
    );
  };
  
  export default ContactForm;