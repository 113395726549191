import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "gatsby";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import SEO from "../components/seo";



const Contact = () => {
  return (
    <Layout>
       <SEO title="Contact" />

   
       <div className="min-h-screen mb-6 flex flex-col items-start">
        <div className="w-3/4 md:w-1/2 pt-6 sm:pt-8 md:pt-16 font-serif font-hairline">
          <h1 className="text-4xl md:text-5xl text-indigo-700">Contact</h1>
        </div>
       




        <div
          className="pt-4 sm:pt-8 md:pt-8 w-full  px-6 py-4 bg-white"
          style={{
            boxShadow:
              "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
          }}
        >

          <p className="pb-2">
          <strong>ABN:</strong>80647215683
          </p>



          <p className="pb-2">
          <strong>Postal Address:</strong> PO Box 76, Hackham, SA 5163
          </p>

          <p className="pb-2">
          To speak to one of our dedicated staff and receive expert advice call us :
          </p>

          <p className="pb-2">
            <strong>Phone:</strong> <a hef="tel:0458480977">0458480977</a>
          </p>

          <p className="pb-4">
          Or leave your message below.
          </p>

          <ContactForm/> 

        </div>

          


      </div>
    </Layout>
  );
};

export default Contact;
